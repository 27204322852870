@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;500;700&family=Nanum+Pen+Script&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Karla", sans-serif;
}

main {
  width: 90%;
  max-width: 1080px;
  margin: 50px auto 0 auto;
}
@media screen and (min-width: 1024px) {
  main {
    width: 80%;
  }
}

ul {
  list-style: none;
}

.button {
  background-color: #e96269;
  padding: 10px 15px;
  margin: 10px 0;
  width: auto;
  border: 2px solid #e96269;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
}
.button:hover {
  background-color: white;
  color: #e96269;
  border: 2px solid white;
}

nav {
  top: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  position: fixed;
}
nav .menuToggle {
  position: absolute;
  width: 28px;
  height: 26px;
  top: 24px;
  right: 30px;
  z-index: 2;
  cursor: pointer;
  color: #e96269;
  transition: ease 0.5s;
}
nav .menuToggle svg {
  width: 100%;
  height: 100%;
}
nav .menuAnimation {
  color: white;
  transition: ease 0.3s;
  animation: animateMenu 0.3s;
}
nav .menu {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.3s;
  flex-direction: column;
  justify-content: center;
  background-color: #ff9090;
}
nav .menu li {
  text-align: center;
  margin-bottom: 40px;
}
nav .menu li a {
  color: white;
  text-decoration: none;
  font-size: 1.7rem;
  font-weight: 700;
}
nav .menu li a:hover {
  color: #e96269;
}
nav .inactive {
  transform: translateY(-100vh);
}

@keyframes animateMenu {
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
.hero {
  display: grid;
  width: 90%;
  height: 100vh;
  max-width: 1080px;
  margin: 0 auto;
  align-content: center;
  grid-template-rows: repeat(2, auto);
}
@media screen and (min-width: 1024px) {
  .hero {
    width: 80%;
  }
}
@media screen and (min-width: 768px) {
  .hero {
    grid-template-columns: repeat(2, auto);
  }
}
.hero .heroText h1 {
  font-weight: 700;
  margin: 10px 0;
  color: #e96269;
  position: relative;
  width: 60%;
  font-size: 2rem;
  max-width: 430px;
}
@media screen and (min-width: 453px) {
  .hero .heroText h1 {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .hero .heroText h1 {
    font-size: 2.5rem;
  }
}
.hero .heroText h1::after {
  content: "";
  position: absolute;
  background-color: #ffe3e3;
  box-shadow: 0 0 8px #ffd6d6;
  height: 23px;
  left: 8px;
  bottom: 0;
  width: 165px;
  z-index: -1;
}
@media screen and (min-width: 453px) {
  .hero .heroText h1::after {
    width: 340px;
  }
}
.hero .heroText p {
  width: 80%;
  color: #696969;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.6;
  margin: 20px 0;
}
.hero .heroText p span {
  color: #e96269;
}
.hero .heroText p .smallerText {
  font-family: "Nanum Pen Script", cursive;
  color: #b3b3b3;
}
.hero .heroText p a {
  text-decoration: none;
  color: #e96269;
}
.hero .heroImage {
  max-width: 230px;
  justify-self: center;
}
.hero .heroImage img {
  width: 100%;
}
.hero .scrollDownIcon {
  position: absolute;
  justify-self: center;
  top: 90vh;
  font-size: 19px;
  color: #e96269;
  display: none;
}
@media screen and (min-width: 453px) {
  .hero .scrollDownIcon {
    display: block;
  }
}
.hero .scrollDownIcon svg {
  animation: scrollDown 1s;
  animation-iteration-count: infinite;
}

@keyframes scrollDown {
  50% {
    transform: translateY(5px);
  }
}
.noteText {
  font-family: "Nanum Pen Script", cursive;
  color: #b3b3b3;
  color: #e96269;
  font-size: 1.3rem;
  max-width: 1080px;
  width: 80%;
}

.projects {
  margin-top: 25px;
  margin-bottom: 100px;
}
.projects .projectsRow {
  display: grid;
  -moz-column-gap: 3.5%;
       column-gap: 3.5%;
  grid-template-rows: auto;
}
@media screen and (min-width: 768px) {
  .projects .projectsRow {
    grid-template-columns: 6fr 4fr;
  }
}
.projects .projectsRow .projectsCard {
  display: flex;
  height: 400px;
  margin-bottom: 5%;
  position: relative;
}
.projects .projectsRow .projectsCard img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  border-radius: 10px;
}
.projects .projectsRow:nth-of-type(2) {
  grid-template-rows: auto;
}
@media screen and (min-width: 768px) {
  .projects .projectsRow:nth-of-type(2) {
    grid-template-columns: 4fr 6fr;
  }
}
.projects .projectsRow .projectsOverlay {
  display: flex;
  flex-direction: column;
  opacity: 0;
  width: 100%;
  height: 400px;
  position: absolute;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  transition: 0.3s ease;
  color: white;
  justify-content: center;
  align-items: center;
}
.projects .projectsRow .projectsOverlay:hover {
  opacity: 1;
}
.projects .projectsRow .projectsOverlay h3 {
  font-size: 1.8rem;
  padding-bottom: 10px;
}
.projects .projectsRow .projectsOverlay p {
  font-size: 1rem;
  width: 70%;
  text-align: center;
  line-height: 1.6;
}
.projects .projectsRow .projectsOverlay .toolsUsed {
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin-top: 20px;
  padding: 5px 0;
}
.projects .projectsRow .projectsOverlay .toolsUsed li {
  background-color: white;
  color: #696969;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 50px;
}
.projects .projectsRow .projectsOverlay .viewProject {
  margin: 20px 0;
  padding: 10px 0;
}
.projects .projectsRow .projectsOverlay .viewProject .button {
  margin: 0 10px;
}

.aboutMe {
  display: grid;
}
@media screen and (min-width: 768px) {
  .aboutMe {
    -moz-column-gap: 5%;
         column-gap: 5%;
    grid-template-columns: 6.5fr 3.5fr;
  }
}
.aboutMe .aboutMeBio {
  display: flex;
  flex-direction: column;
  grid-column: 1/3;
}
@media screen and (min-width: 768px) {
  .aboutMe .aboutMeBio {
    grid-column: 1/2;
  }
}
.aboutMe .aboutMeBio h2 {
  position: relative;
  font-size: 1.7rem;
  color: #e96269;
}
.aboutMe .aboutMeBio h2::after {
  content: "";
  position: absolute;
  background-color: #ffe3e3;
  box-shadow: 0 0 8px #ffd6d6;
  height: 18px;
  left: 5px;
  bottom: 0;
  width: 150px;
  z-index: -1;
}
.aboutMe .aboutMeBio p {
  font-size: 1.1rem;
  color: #696969;
  line-height: 1.6;
  margin: 18px 0;
}
.aboutMe .aboutMeBio p a {
  text-decoration: none;
  color: #e96269;
}
.aboutMe .aboutMeImage {
  grid-column: 1/3;
  justify-self: center;
  margin-bottom: 100px;
}
@media screen and (min-width: 768px) {
  .aboutMe .aboutMeImage {
    grid-column: 2/3;
  }
}
.aboutMe .aboutMeImage img {
  width: 100%;
  border-radius: 10px;
  height: 360px;
  -o-object-fit: cover;
     object-fit: cover;
}
.aboutMe .aboutMeToolkit {
  grid-column: 1/3;
}
.aboutMe .aboutMeToolkit h2 {
  position: relative;
  font-size: 1.7rem;
  color: #e96269;
}
.aboutMe .aboutMeToolkit h2::after {
  content: "";
  position: absolute;
  background-color: #ffe3e3;
  box-shadow: 0 0 8px #ffd6d6;
  height: 18px;
  left: 5px;
  bottom: 0;
  width: 195px;
  z-index: -1;
}
.aboutMe .aboutMeToolkit ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px auto 60px auto;
  padding: 20px 0;
  font-family: "Nanum Pen Script", "cursive";
}
@media screen and (min-width: 768px) {
  .aboutMe .aboutMeToolkit ul {
    width: 90%;
  }
}
.aboutMe .aboutMeToolkit ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  padding: 20px 0;
  width: 120px;
  text-align: center;
}
.aboutMe .aboutMeToolkit ul li img {
  width: 50px;
  margin-bottom: 5px;
}

.contact {
  padding: 100px 0;
  background-color: #fff7f7;
  font-size: 1rem;
}
.contact .contactContainer {
  display: grid;
  width: 90%;
  max-width: 1080px;
  margin: 0 auto;
}
@media screen and (min-width: 1024px) {
  .contact .contactContainer {
    width: 80%;
    grid-template-columns: 3fr 7fr !important;
  }
}
@media screen and (min-width: 768px) {
  .contact .contactContainer {
    grid-template-columns: 4fr 6fr;
    -moz-column-gap: 10%;
         column-gap: 10%;
  }
}
.contact .contactContainer .contactInformation {
  display: flex;
  flex-direction: column;
}
.contact .contactContainer .contactInformation h2 {
  position: relative;
  font-size: 1.7rem;
  color: #e96269;
  z-index: 0;
  width: 100%;
}
.contact .contactContainer .contactInformation h2::after {
  content: "";
  position: absolute;
  background-color: #ffe3e3;
  box-shadow: 0 0 8px #ffd6d6;
  height: 18px;
  left: 5px;
  bottom: 0;
  width: 275px;
  z-index: -1;
}
@media screen and (min-width: 768px) {
  .contact .contactContainer .contactInformation h2 {
    width: 130%;
  }
}
.contact .contactContainer .contactInformation p {
  margin: 30px 0 20px 0;
  line-height: 1.6;
}
.contact .contactContainer .contactInformation .contactIcons {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}
.contact .contactContainer .contactInformation .contactIcons li {
  font-size: 1.4rem;
}
.contact .contactContainer .contactInformation .contactIcons li a {
  color: #e96269;
}
.contact .contactContainer .contactInformation .contactIcons li a:hover {
  color: #ba3130;
}
.contact .contactContainer .contactForm form {
  display: flex;
  flex-direction: column;
}
.contact .contactContainer .contactForm form input {
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #ffd6d6;
  border-radius: 5px;
  font-family: inherit;
  font-size: 90%;
}
.contact .contactContainer .contactForm form textarea {
  height: 200px;
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #ffd6d6;
  border-radius: 5px;
  resize: none;
  font-family: inherit;
  font-size: 90%;
}
.contact .contactContainer .contactForm form .hidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  -webkit-clip-path: inset(100%);
          clip-path: inset(100%);
}
.contact .contactContainer .contactForm form .submitContainer .button:hover {
  border: 2px solid #ffd6d6;
}
.contact .contactContainer .contactForm form .submitContainer .confirmationModal {
  display: none;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
  animation: animateModalBackground 0.4s;
}
.contact .contactContainer .contactForm form .submitContainer .modalContent {
  background-color: #fff;
  margin: 15% auto;
  padding: 20px;
  border: 2px solid #ffe3e3;
  border-radius: 5px;
  width: 90%;
  animation: animateModal 0.4s;
}
@media screen and (min-width: 640px) {
  .contact .contactContainer .contactForm form .submitContainer .modalContent {
    width: 70%;
  }
}
@media screen and (min-width: 1024px) {
  .contact .contactContainer .contactForm form .submitContainer .modalContent {
    width: 50%;
  }
}
@media screen and (min-width: 1280px) {
  .contact .contactContainer .contactForm form .submitContainer .modalContent {
    width: 30%;
  }
}
.contact .contactContainer .contactForm form .submitContainer .modalClose {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
}
.contact .contactContainer .contactForm form .submitContainer .modalClose:hover, .contact .contactContainer .contactForm form .submitContainer .modalClose:focus {
  color: #e96269;
  cursor: pointer;
}

@keyframes animateModalBackground {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.7);
  }
}
@keyframes animateModal {
  0% {
    margin: 10% auto;
  }
  100% {
    margin: 15% auto;
  }
}
footer {
  width: 100%;
  position: relative;
  background-color: #ff9090;
  padding: 15px;
  text-align: center;
  color: white;
}/*# sourceMappingURL=main.css.map */
.button {
  background-color: #e96269;
  padding: 10px 15px;
  margin: 10px 0;
  width: auto;
  border: 2px solid #e96269;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background-color: white;
    color:#e96269;
    border: 2px solid white;
  }
}
@use "../abstracts" as *;

.aboutMe {
  display: grid;
  @include mq(md) {
    column-gap: 5%;
    grid-template-columns: 6.5fr 3.5fr;
  }
  .aboutMeBio {
    display: flex;
    flex-direction: column;
    grid-column: 1/3;
    @include mq(md) {
      grid-column: 1/2;
    }
    h2 {
      @include h2(150px);
    }
    p {
      font-size: 1.1rem;
      color: $textColor;
      line-height: 1.6;
      margin: 18px 0;
      a {
        text-decoration: none;
        color: $complementaryColor;
      }
    }
  }
  .aboutMeImage {
    grid-column: 1/3;
    justify-self: center;
    margin-bottom: 100px;
    @include mq(md) {
      grid-column: 2/3;
    }
    img {
      width: 100%;
      border-radius: 10px;
      height: 360px;
      object-fit: cover;
    }
  }
  .aboutMeToolkit {
    grid-column: 1 / 3;
    h2 {
      @include h2(195px);
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 20px auto 60px auto;
      padding: 20px 0;
      font-family: "Nanum Pen Script", "cursive";
      @include mq(md) {
        width: 90%;
      }
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 20px;
        padding: 20px 0;
        width: 120px;
        text-align: center;
        img {
          width: 50px;
          margin-bottom: 5px;
        }
      }
    }
  }
}
@use "../abstracts" as *;

.noteText {
  @include smallerText();
  color: $complementaryColor;
  font-size: 1.3rem;
  max-width: 1080px;
  width: 80%;
}

.projects {
  margin-top: 25px;
  margin-bottom: 100px;
  .projectsRow {
    display: grid;
    column-gap: 3.5%;
    grid-template-rows: auto;
    @include mq(md) {
      @include projectsRow(6fr, 4fr);
    }
    .projectsCard {
      display: flex;
      height: 400px;
      margin-bottom: 5%;
      position: relative;
      img {
        object-fit: cover;
        width: 100%;
        border-radius: 10px;
      }
    }
    &:nth-of-type(2) {
      grid-template-rows: auto;
      @include mq(md) {
        @include projectsRow(4fr, 6fr);
      }
    }
    .projectsOverlay {
      display: flex;
      flex-direction: column;
      opacity: 0;
      width: 100%;
      height: 400px;
      position: absolute;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.7);
      transition: 0.3s ease;
      color: white;
      justify-content: center;
      align-items: center;
      &:hover {
        opacity: 1;
      }
      h3 {
        font-size: 1.8rem;
        padding-bottom: 10px;
      }
      p {
        font-size: 1rem;
        width: 70%;
        text-align: center;
        line-height: 1.6;
      }
      .toolsUsed {
        display: flex;
        justify-content: space-around;
        width: 80%;
        margin-top: 20px;
        padding: 5px 0;
        li {
          background-color: white;
          color: $textColor;
          padding: 5px 10px;
          margin: 5px;
          border-radius: 50px;
        }
      }
      .viewProject {
        margin: 20px 0;
        padding: 10px 0;
        .button {
          margin: 0 10px;
        }
      }
    }
  }
}

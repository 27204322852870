@use "../abstracts/mixins" as *;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Karla", sans-serif;
}

main {
  width: 90%;
  max-width: 1080px;
  margin: 50px auto 0 auto;
  @include mq(lg) {
    width: 80%;
  }
}

ul {
  list-style: none;
}
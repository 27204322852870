@use "../abstracts" as *;

.contact {
  padding: 100px 0;
  background-color: $contactBackground;
  font-size: 1rem;
  .contactContainer {
    display: grid;
    width: 90%;
    max-width: 1080px;
    margin: 0 auto;
    @include mq(lg) {
      width: 80%;
      grid-template-columns: 3fr 7fr !important;
    }
    @include mq(md) {
      grid-template-columns: 4fr 6fr;
      column-gap: 10%;
    }
    .contactInformation {
      display: flex;
      flex-direction: column;
      h2 {
        @include h2(275px);
        z-index: 0;
        width: 100%;
        @include mq(md) {
          width: 130%;
        }
      }
      p {
        margin: 30px 0 20px 0;
        line-height: 1.6;
      }
      .contactIcons {
        display: flex;
        justify-content: space-around;
        margin: 20px 0;
        li {
          font-size: 1.4rem;
          a {
            color: $complementaryColor;
            &:hover {
              color: #ba3130;
            }
          }
        }
      }
    }
    .contactForm {
      form {
        display: flex;
        flex-direction: column;
        input {
          padding: 10px;
          margin: 10px 0;
          border: 2px solid $textBoxShadow;
          border-radius: 5px;
          font-family: inherit;
          font-size: 90%;
        }
        textarea {
          height: 200px;
          padding: 10px;
          margin: 10px 0;
          border: 2px solid $textBoxShadow;
          border-radius: 5px;
          resize: none;
          font-family: inherit;
          font-size: 90%;
        }
        .hidden {
          &:not(:focus):not(:active) {
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            clip-path: inset(100%);
          }
        }
        .submitContainer {
          .button {
            &:hover {
              border: 2px solid #ffd6d6;
            }
          }
          .confirmationModal {
            display: none;
            position: fixed;
            z-index: 999;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            background-color: rgba(0, 0, 0, 0.7);
            animation: animateModalBackground 0.4s;
          }
          .modalContent {
            background-color: #fff;
            margin: 15% auto;
            padding: 20px;
            border: 2px solid $textBackdrop;
            border-radius: 5px;
            width: 90%;
            animation: animateModal 0.4s;
            @include mq(sm) {
              width: 70%;
            }
            @include mq(lg) {
              width: 50%;
            }
            @include mq(xl) {
              width: 30%;
            }
          }
          .modalClose {
            float: right;
            font-size: 1.5rem;
            font-weight: bold;
            &:hover, &:focus {
              color: $complementaryColor;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@keyframes animateModalBackground {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

@keyframes animateModal {
  0% {
    margin: 10% auto;
  }
  100% {
    margin: 15% auto;
  }
}
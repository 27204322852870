@use "../abstracts" as *;

.hero {
  display: grid;
  width: 90%;
  height: 100vh;
  max-width: 1080px;
  margin: 0 auto;
  align-content: center;
  grid-template-rows: repeat(2, auto);
  @include mq(lg) {
    width: 80%;
  }
  @include mq(md) {
    grid-template-columns: repeat(2, auto);
  }
  .heroText {
    h1 {
      font-weight: 700;
      margin: 10px 0;
      color: $complementaryColor;
      position: relative;
      width: 60%;
      font-size: 2rem;
      max-width: 430px;
      @include mq(xs) {
        width: 100%;
      }
      @include mq(md) {
        font-size: 2.5rem;
      }
      &::after {
        content: "";
        position: absolute;
        background-color: $textBackdrop;
        box-shadow: 0 0 8px $textBoxShadow;
        height: 23px;
        left: 8px;
        bottom: 0;
        width: 165px;
        z-index: -1;
        @include mq(xs) {
          width: 340px;
        }
      }
    }
    p {
      width: 80%;
      color: $textColor;
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 1.6;
      margin: 20px 0;
      span {
        color: $complementaryColor;
      }
      .smallerText {
        @include smallerText();
      }
      a {
        text-decoration: none;
        color: $complementaryColor;
      }
    }
  }
  .heroImage {
    max-width: 230px;
    justify-self: center;
    img {
      width: 100%;
    }
  }
  .scrollDownIcon {
    position: absolute;
    justify-self: center;
    top: 90vh;
    font-size: 19px;
    color: $complementaryColor;
    display: none;
    @include mq(xs) {
      display: block;
    }
    svg {
      animation: scrollDown 1s;
      animation-iteration-count: infinite;
    }
  }
}

@keyframes scrollDown {
  50% {
    transform: translateY(5px);
  }
}
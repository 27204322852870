@use "./variables" as *;

@mixin smallerText {
  font-family: "Nanum Pen Script", cursive;
  color: #b3b3b3;
}

@mixin projectsRow($column-left, $column-right) {
  grid-template-columns: $column-left $column-right;
}

@mixin h2($width) {
  position: relative;
  font-size: 1.7rem;
  color: $complementaryColor;
  &::after {
    content: "";
    position: absolute;
    background-color: $textBackdrop;
    box-shadow: 0 0 8px $textBoxShadow;
    height: 18px;
    left: 5px;
    bottom: 0;
    width: $width;
    z-index: -1;
  }
}

$media: (
  xs: 453px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  2xl: 1536px,
  3xl: 1920px,
);

@mixin mq($breakpoint) {
  @each $breakpoints, $size in $media {
    @if $breakpoint==$breakpoints {
      @media screen and (min-width: $size) {
        @content;
      }
    }
  }
}
